import React, { ReactNode, useRef } from "react";
import { StoryProvider } from "@storyofams/storyblok-toolkit";
import { PostHog, PostHogProvider } from "posthog-js/react";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { ThemeProvider } from "styled-components";
import { ShopifyProvider, GrowaveProvider } from "~context";

import theme from "~styles/theme";

type ProviderProps = {
  children: ReactNode;
  client: PostHog;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to 'nl' as per the defaultLocale */
  locale?: string;
};

export const Providers = ({
  children,
  client,
  locale = "nl",
  pageProps = {},
}: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale}
          >
            <StoryProvider
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
              resolveRelations={[
                "blog_bar.blog_list",
                "call_to_action.call_to_action",
                "usp_bar.usp",
              ]}
            >
              <ShopifyProvider>
                <GrowaveProvider>
                  <PostHogProvider client={client}>{children}</PostHogProvider>
                </GrowaveProvider>
              </ShopifyProvider>
            </StoryProvider>
          </IntlProvider>
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
