async function generateCodeVerifier() {
  const rando = generateRandomCode();
  return base64UrlEncode(rando);
}
async function generateCodeChallenge(codeVerifier: string) {
  const digestOp = await self.crypto.subtle.digest(
    { name: "SHA-256" },
    new TextEncoder().encode(codeVerifier)
  );
  const hash = convertBufferToString(digestOp);
  return base64UrlEncode(hash);
}
function generateRandomCode() {
  const array = new Uint8Array(32);
  self.crypto.getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array));
}
function base64UrlEncode(str: string) {
  const base64 = btoa(str);
  // This is to ensure that the encoding does not have +, /, or = characters in it.
  return base64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")
    .replace(/" "/g, "")
    .trim();
}
function convertBufferToString(hash: ArrayBuffer) {
  const uintArray = new Uint8Array(hash);
  const numberArray = Array.from(uintArray);
  return String.fromCharCode(...numberArray);
}
async function generateState(): Promise<string> {
  const timestamp = Date.now().toString();
  const randomString = Math.random().toString(36).substring(2);
  return timestamp + randomString;
}
export async function generateCode() {
  const clientId = process.env.NEXT_PUBLIC_STOREFRONT_CLIENT_ID;
  const authorizationRequestUrl = new URL(
    `https://shopify.com/10206689/auth/oauth/authorize`
  );
  const state = await generateState();
  authorizationRequestUrl.searchParams.append(
    "scope",
    "openid email https://api.customers.com/auth/customer.graphql"
  );
  authorizationRequestUrl.searchParams.append("client_id", clientId);
  authorizationRequestUrl.searchParams.append("response_type", "code");
  authorizationRequestUrl.searchParams.append(
    "redirect_uri",
    `${window ? window.location.origin : ""}/account`
  );
  authorizationRequestUrl.searchParams.append("state", state);
  // Public client
  const verifier = await generateCodeVerifier();
  const challenge = await generateCodeChallenge(verifier);
  localStorage.setItem("code-verifier", verifier);
  authorizationRequestUrl.searchParams.append("code_challenge", challenge);
  authorizationRequestUrl.searchParams.append("code_challenge_method", "S256");
  return authorizationRequestUrl;
}
