import React, { useRef, useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { withPasswordProtect } from "next-password-protect";
import { DefaultSeo } from "next-seo";
import NProgress from "nprogress";
import posthog from "posthog-js";
import { Providers, UserAgent } from "../components";
import { Container as ToastContainer } from "../components/common/Toast/Container";
import { seo } from "../config";
import { useDetectKeyboard } from "../hooks";
import CSSreset from "../styles/CSSreset";
import "react-toastify/dist/ReactToastify.min.css";
import "nprogress/nprogress.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  import("../lib/sentry").then((m) => m.initSentry());
}

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
    capture_pageview: true,
    autocapture: false,
    capture_pageleave: true,
  });
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const locale = "nl";
  const router = useRouter();
  const progressTimer = useRef<any>(null);

  useDetectKeyboard();

  useEffect(() => {
    const startProgress = () => {
      if (!progressTimer.current) {
        progressTimer.current = setTimeout(NProgress.start, 120);
      }
    };

    const onComplete = (url) => {
      endProgress(url);
    };

    const endProgress = (err) => {
      if (progressTimer.current) {
        clearTimeout(progressTimer.current);
        progressTimer.current = null;

        if (err?.cancelled) {
          NProgress.set(0.0);
          NProgress.remove();
        } else {
          NProgress.done();
        }
      }
    };

    const handleRouteChange = () => posthog?.capture("$pageview");

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeStart", startProgress);
    router.events.on("routeChangeComplete", onComplete);
    router.events.on("routeChangeError", endProgress);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeStart", startProgress);
      router.events.off("routeChangeComplete", onComplete);
      router.events.off("routeChangeError", endProgress);
    };
  }, [router.events]);

  return (
    <Providers pageProps={pageProps} locale={locale} client={posthog}>
      <CSSreset />
      <DefaultSeo {...seo} />
      <UserAgent />
      <Component {...pageProps} />
      <ToastContainer />
      <Analytics />
    </Providers>
  );
};

export default withPasswordProtect(MyApp, {
  loginComponentProps: {
    buttonBackgroundColor: "#010406",
    buttonColor: "#FCF9F7",
    logo: "/images/nh_logo.png",
  },
  bypassProtection(route) {
    if (process.env.NEXT_PUBLIC_WHOLESALE_DEV) return false;
    return true;
  },
});
