import { Breakpoints } from "../styled";
import space from "./space";

const theme = {
  colors: {
    oldPink: "#C39A91",
    soft: "#EEE3D7",
    sand: "#E2DFD9",
    white: "#ffffff",
    greenish: "#B7C2B9",
    grey100: "#F4F3F0",
    grey200: "#E2DFDF",
    grey300: "#FCF9F7",
    grey400: "#C1BEBD",
    grey600: "#767473",
    grey800: "#1D1D1B",
    grey900: "#151817",
    warning50: "#FEFCE8",
    warning100: "#FEF08A",
    warning300: "#FACC15",
    warning600: "#CA8A04",
    warning800: "#713F12",
    success50: "#F0FDF4",
    success100: "#BBF7D0",
    success300: "#4ADE80",
    success600: "#16A34A",
    success800: "#14532D",
    error50: "#FEEEEC",
    error100: "#FDDDD8",
    error300: "#F9988B",
    error600: "#F66754",
    error800: "#741306",
    transparent: "rgba(255, 255, 255, 0);",
  },
  fontWeights: {
    normal: "normal",
    medium: 500,
    bold: "bold",
  },
  fonts: {
    heading: `DINPro, sans-serif`,
    body: `'Barlow', sans-serif`,
    mono: `SFMono-Regular, Menlo, Monaco,C onsolas, "Liberation Mono", "Courier New", monospace`,
  },
  fontSizes: {
    ...space,
    root: space["2"],
    heading: space["4"],
  },
  lineHeights: {
    normal: 1,
    medium: 1.2,
    high: 1.6,
  },
  space: {
    ...space,
    mobileGutter: space["2"],
  },
  sizes: {
    maxWidth: 1360,
  },
  breakpoints: ["768px", "1024px", "1280px", "1440px"] as Breakpoints,
  zIndices: {
    hide: -1,
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  radii: {
    none: "0",
    xs: "4px",
    sm: "6px",
    md: "8px",
    lg: "16px",
    full: "9999px",
  },
  borders: {
    none: 0,
    "1px": "1px solid",
    "2px": "2px solid",
    "4px": "4px solid",
  },
  shadows: {
    sm: "0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)",
    md: "0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)",
    lg: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1)",
    nav: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    none: "none",
  },
};

theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

export default theme;
