import crypto from "crypto";
import qs from "querystring";
import {
  FC,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useState,
} from "react";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";
import { includes, isNumber } from "lodash";
import { useRouter } from "next/router";
import { useQuery, useQueryClient } from "react-query";

import { useShopify } from "~context";
import { formatShopifyIdForTracking, showToast } from "~lib";
import { getShopifySdk } from "~lib/shopify/client";

const shopifyIdBase = "gid://shopify/Product/";

const LOCAL_STORAGE_WISHLIST = "wishlist";

export const transformId = (id, type = "numeric") => {
  if (!id) return 0;

  return type === "numeric"
    ? isNumber(id)
      ? id
      : id.replace(shopifyIdBase, "")
    : btoa(`${shopifyIdBase}${id}`);
};

export interface GrowaveContextType {
  wishlist: any;
  loadingWishlist: boolean;
  addOrRemoveFromWishlist: (action: string, data: any) => void;
  wishlistCount: number;
}

export const GrowaveContext = createContext<GrowaveContextType>(undefined);
export const GrowaveProvider: FC = ({ children }) => {
  const [wishlistCount, setWishlistCount] = useState(0);
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_WISHLIST_TOKEN}`,
    },
  };
  const router = useRouter();
  const queryClient = useQueryClient();

  const { customer } = useShopify();

  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale]
  );
  const getShopifyProducts = async (items) => {
    if (items?.length) {
      const ids = items
        ?.filter((item) => !item.images && item.product_id)
        .map((item) =>
          isNumber(Number(item?.product_id))
            ? transformId(item.product_id, "string")
            : item.product_id
        );

      if (ids?.length) {
        try {
          const shopifyProducts = (await shopifySdk.productsByIds({ ids }))
            ?.nodes;

          return items?.map((item) => ({
            ...item,
            ...shopifyProducts?.find(
              (prod) =>
                // @ts-ignore
                item?.handle === prod?.handle || item?.product_id === prod?.id
            ),
          }));
        } catch (err) {
          console.log(err);
        }
      } else return items;
    } else return [];
  };
  const getWishlist = async () => {
    try {
      if (customer?.id) {
        const { data } = await axios(
          `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/products?per_page=50&customer_id=${formatShopifyIdForTracking(
            customer?.id
          )}`,
          config
        );
        setWishlistCount(data.total);
        const items = await getShopifyProducts(data.data);
        return items;
      } else {
        const items = localStorage.getItem(LOCAL_STORAGE_WISHLIST);

        const data = await getShopifyProducts(
          items !== "undefined" ? JSON.parse(items) : []
        );
        setWishlistCount(data.length);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const {
    data: wishlist,
    isLoading: loadingWishlist,
    refetch: refetchWishlist,
  } = useQuery(["getWishlist"], getWishlist);
  useEffect(() => {
    if (customer?.id) {
      const wishlistItem = localStorage.getItem(LOCAL_STORAGE_WISHLIST);
      const tempWishlist =
        wishlistItem === "undefined" || !wishlistItem || wishlistItem === "null"
          ? []
          : JSON.parse(wishlistItem);
      const ids = tempWishlist.map((item) => {
        return {
          productId: item.id,
          variantId: item?.variants?.edges[0]?.node?.id || item?.objectID,
        };
      });
      if (ids?.length) {
        ids?.forEach(({ productId, variantId }) =>
          addOrRemoveFromWishlist("add", {
            productId,
            variantId,
          })
        );
        localStorage.setItem(LOCAL_STORAGE_WISHLIST, JSON.stringify([]));
      }
      refetchWishlist();
    }
  }, [customer?.id]);
  const addOrRemoveFromWishlist = async (
    action,
    { productId, variantId, product = {} }
  ) => {
    const numericProductId = isNaN(Number(productId))
      ? formatShopifyIdForTracking(productId)
      : Number(productId);
    const numericVariantId = isNaN(Number(variantId))
      ? formatShopifyIdForTracking(variantId)
      : Number(variantId);
    if (customer?.id) {
      try {
        if (action === "add") {
          await axios.post(
            `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/add_product?product_id=${numericProductId}&customer_id=${formatShopifyIdForTracking(
              customer?.id
            )}&variant_id=${numericVariantId}`,
            null,
            config
          );
        }

        if (action === "remove") {
          await axios.post(
            `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/delete_product?product_id=${numericProductId}&customer_id=${formatShopifyIdForTracking(
              customer?.id
            )}&variant_id=${numericVariantId}`,
            null,
            config
          );
        }
        refetchWishlist();
      } catch (err) {
        console.log(err);
      }
    } else {
      const updatedWishlist =
        action === "add"
          ? [...(wishlist || []), { product_id: productId, ...product }]
          : wishlist?.filter((item) => item.id !== productId);
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      refetchWishlist();
    }

    queryClient.refetchQueries("wishlist");
  };
  return (
    <GrowaveContext.Provider
      value={{
        // Wishlist
        wishlist,
        wishlistCount,
        loadingWishlist,
        addOrRemoveFromWishlist,
      }}
    >
      {children}
    </GrowaveContext.Provider>
  );
};

export const useStamped = () => {
  const ctx = useContext(GrowaveContext);

  if (!ctx) {
    throw new Error(
      "Growave context must be used within a growave context provider"
    );
  }

  return ctx;
};
